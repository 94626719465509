.cart-slideout {
	@apply bg-white
		fixed
        flex
        flex-col
		inset-y-0
		right-0
		w-full
		z-30;
	max-width: 480px;
	transform: translateX(105%);
	transition: transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	&.is-open {
		transform: translateX(0);
	}
}

.modal-bg {
	@apply fixed inset-0 z-20 bg-bbx-style transform-gpu;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	&.is-open {
		pointer-events: auto;
		opacity: 0.7;
	}
}
