/* Default background image */
.members-market-bg {
	background-image: url('/images/members-market-bg-mobile.jpg');
	background-size: auto;
	background-position: top center;
	background-repeat: no-repeat;
	height: 100vh; /* Adjust height as needed */
}

/* For medium screens and larger */
@media (min-width: 768px) {
	.members-market-bg {
		background-image: url('/images/members-market-bg.jpg');
	}
}
