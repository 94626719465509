// Altone

// Medium
// @font-face {
// 	font-family: 'Altone';
// 	src: url('/fonts/altone/altone.woff2') format('woff2'),
// 		url('/fonts/altone/altone.woff') format('woff');
// 	font-style: normal;
// 	font-weight: 300 500;
// 	font-display: swap;
//}

// Medium Italic
// @font-face {
// 	font-family: 'Altone';
// 	src: url('/fonts/altone/altone-oblique.woff2') format('woff2'),
// 		url('/fonts/altone/altone-oblique.woff') format('woff');
// 	font-style: italic;
// 	font-weight: 300 500;
// 	font-display: swap;
// }

// Studio Feixen Serif

// Regular
@font-face {
	font-family: 'Studio Feixen Serif';
	src: url('/fonts/studio-feixen-serif/StudioFeixenSerif-Regular.woff2') format('woff2');
	font-style: normal;
	font-weight: 300;
	font-display: swap;
}

// Studio Feixen Sans

// Book
@font-face {
	font-family: 'Studio Feixen Sans';
	src: url('/fonts/studio-feixen-sans/StudioFeixenSans-Book.woff2') format('woff2');
	font-style: normal;
	font-weight: 300;
	font-display: swap;
}

// Regular
@font-face {
	font-family: 'Studio Feixen Sans';
	src: url('/fonts/studio-feixen-sans/StudioFeixenSans-Regular.woff2') format('woff2');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

// Medium
@font-face {
	font-family: 'Studio Feixen Sans';
	src: url('/fonts/studio-feixen-sans/StudioFeixenSans-Medium.woff2') format('woff2');
	font-style: normal;
	font-weight: 500;
	font-display: swap;
}

// Bold
@font-face {
	font-family: 'Studio Feixen Sans';
	src: url('/fonts/studio-feixen-sans/StudioFeixenSans-Bold.woff2') format('woff2');
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}
