// Product card vendor title
.box-product-card-title {
	@apply text-sm
        md:text-base
        mt-4
        font-medium
        line-clamp-2;

	.size-2 & {
		@apply text-base md:text-lg;
	}
}

// Product card image wrapper
.box-product-card-image-wrapper {
	@apply relative inline-block aspect-square shrink-0;
	.size-1 & {
		width: 68px;

		@screen md {
			width: 88px;
		}
	}
	.size-2 & {
		width: 96px;

		@screen md {
			width: 128px;
		}

		@screen lg {
			width: 160px;
		}
	}

	.size-4 & {
		@apply w-36 lg:w-64;
	}

	.size-6 & {
		@apply w-44 lg:w-72;
	}
}

// Product card size label
.box-product-card-size-label {
	@apply absolute
        right-0
        pt-1.5
        pb-1
        lg:pt-2
        lg:pb-1.5
        rounded-l-sm
        z-10
        font-medium
        text-xs
        lg:text-sm;
	.size-2 & {
		@apply top-2 lg:top-6 bg-bbx-blue-light px-3 lg:px-5;
	}
	.size-4 & {
		@apply top-4 lg:top-8 bg-bbx-pink px-3.5 lg:px-6;
	}
}

// Product card content wrapper
.box-product-card-content-wrapper {
	@apply text-center;
	// Left-aligned for larger cards
	.size-2 & {
		@apply text-left justify-center;
	}
}

// Product card short description
.box-product-card-description {
	@apply mt-1
        lg:mt-2
        flex-shrink-0
        line-clamp-2
        lg:line-clamp-3;

	// TODO: Fix overflow bug
	.size-1 & {
		@apply text-xs lg:text-sm mt-1.5;
		line-height: 1.25;
	}

	.size-2 & {
		@apply max-lg:text-sm text-left;
	}
}

// Product card description
// .box-product-card-description {
// 	@apply text-sm text-gray-500 mt-4 lg:mt-8 text-center;
// }

// Product card cta message wrapper
.box-product-card-cta-message-wrapper {
	@apply relative
        flex
        flex-col
        items-center
        text-center
        mt-auto
        mt-3
        lg:mt-7
        z-20;

	// Go Back to the Box to Remove n More Items text
	span:nth-child(2) {
		@apply block mt-1 font-medium leading-tight md:mt-2 text-bbx-spark lg:leading-tight;
	}

	.size-2 & {
		@apply text-left items-start;
	}

	.size-4 &,
	.size-6 & {
		@apply mt-8 lg:mt-16;
	}
}

// Add item card
.add-item-card {
	@apply flex flex-col items-center justify-center w-full space-y-3 transition-colors duration-200 ease-out bg-transparent rounded text-gray-500 hover:bg-bbx-style/5 md:space-y-4;
	height: 200px;
	@screen lg {
		height: 344px;
	}
}

// Products picker overlay grid - (modal where products are chosen)
.products-picker-overlay-grid {
	max-width: 1008px;
	@apply grid grid-flow-row-dense grid-cols-2 gap-4 md:gap-8 mx-auto mt-16 lg:grid-cols-3;
}

// Subscription box SVG
.subscription-box-wrapper {
	transition-property: transform;
	transition-duration: 1000ms;
	transition-delay: 0ms;
	transition-timing-function: ease-in-out;
	@apply max-w-screen-md w-full absolute top-0;

	// Open box state
	&.open {
		transform: translate(0, 600px);

		@screen lg {
			transform: translate(0, 640px);
		}
		transition-delay: 0ms;

		.subscription-box-lid {
			transition-delay: 100ms;
			transform: translate(0, 3px) rotate3d(0.5, 0, 0, 175deg);
		}

		.birchbox-text {
			@apply opacity-0;
		}
	}
}

// Curate subscription box animation
.curate-subscription-box {
	animation-name: subscriptionBoxSlideUpAnimation;
	animation-duration: 4000ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}

@keyframes subscriptionBoxSlideUpAnimation {
	0% {
		transform: translate(0, 40px);
	}
	100% {
		transform: translate(0, 0px);
	}
}

.scale-up-subscription-box-animation {
	animation-name: subscriptionBoxScaleUpAnimation;
	animation-duration: 400ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-in-out;
}

@keyframes subscriptionBoxScaleUpAnimation {
	0% {
		transform: scale(0.85);
	}
	75% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

// Subscription box SVG lid
.subscription-box-lid {
	transition-property: transform;
	transition-duration: 1000ms;
	transition-timing-function: ease-in-out;
	transition-delay: 100ms;

	@apply absolute origin-top top-0 left-0 z-10;
}

// Subscription box SVG Birchbox text / logo
.birchbox-text {
	@apply transition-opacity duration-300 ease-in-out;
}

// Subscription box SVG Ship box button
.ship-box-button {
	@apply absolute md:bottom-16 left-1/2 -translate-x-1/2 translate-y-1/2 text-sm md:text-base whitespace-nowrap;
}

// Skin sensitivity slider
.range-slider {
	@apply block items-center w-full bg-transparent appearance-none cursor-pointer border-none px-0 z-20;

	// Chrome, Safari etc..
	&::-webkit-slider-runnable-track {
		@apply h-8 rounded-full;
	}

	&::-webkit-slider-thumb {
		@apply appearance-none w-8 h-8 rounded-full bg-white shadow-none;
		border: 2px solid #f98979;
	}

	// Fireox
	&::-moz-range-track {
		@apply h-0.5 rounded-full z-20 relative;
	}

	&::-moz-range-thumb {
		@apply appearance-none w-8 h-8 rounded-full bg-white shadow-none;
		border: 2px solid #f98979;
	}
}

// Recommendation tags
.recommendation-tag {
	@apply px-4 py-2 text-xs font-medium text-center border rounded-sm bg-bbx-pearl-light border-bbx-pearl;
}

// Selected products grid
.selected-products-grid {
	max-width: 1008px;
	background: #f8f8f850;
	@apply grid grid-cols-2 lg:grid-cols-3 border-t border-l border-dashed w-full border-gray-500 backdrop-blur-sm;

	.box-product-card {
		@apply opacity-100 translate-y-0;
	}
}

// Product card itself
.box-product-card {
	@apply relative bg-white shadow-lg flex items-center w-full h-full rounded;

	// Default variant
	// Smallest
	&.size-1 {
		@apply flex-col justify-center p-3 lg:p-8;
		// TODO: Remove fixed heights eventually
		height: 200px;
		// height: 100%;

		@screen lg {
			height: 344px;
		}
	}

	// Premium variant
	// Mid-size
	&.size-2 {
		@apply col-span-2
            justify-center
            gap-5
            lg:gap-10
            p-8
            lg:px-20
            lg:py-24;

		height: 200px;

		@screen lg {
			height: 344px;
		}
	}

	&.size-4,
	&.size-6 {
		@apply flex-col
            col-span-2
            row-span-2
            p-12
            lg:px-16
            lg:pt-16
            lg:pb-20;
	}

	// Full size variant
	// Large
	.size-4 & {
		height: 400px;
		@screen lg {
			height: 720px;
		}
	}

	// Biggest variant
	.size-6 & {
		height: 600px;
		@screen lg {
			height: 1080px;
		}
	}
}

// Product card with variations
.box-product-card-wrapper {
	// Dotted border around box item
	@apply p-2
        md:p-4
        border-r
        border-b
        border-dashed
        border-gray-500;

	&.size-2 {
		// order: -2;
		@apply col-span-2;
	}

	&.size-4 {
		// order: -1;
		@apply col-span-2 row-span-2;
	}

	&.size-6 {
		@apply col-span-full row-span-full;
	}

	/* premium on top and full-size on bottom */
	/* full-size on top and premium on bottom */
	/* Three premium items chosen */
	&.size-2:first-child:nth-last-child(2),
	&.size-2:first-child:nth-last-child(2) ~ .size-4,
	&.size-4:first-child:nth-last-child(2),
	&.size-4:first-child:nth-last-child(2) ~ .size-2,
	&.size-2:first-child:nth-last-child(3),
	&.size-2:first-child:nth-last-child(3) ~ .size-2 {
		@apply col-span-full;
	}
}
