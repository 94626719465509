.theme-white {
}

.theme-light-spark {
	@apply bg-bbx-pearl-lightest;
}

.theme-spark {
	@apply bg-bbx-spark text-white;
}
