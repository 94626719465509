.product-card {
	@apply flex
        flex-col
        text-sm
        border
        h-full
        py-6
        md:px-3
        overflow-hidden
        border-transparent bg-white;
	transition: border-color 0.3s ease;
	&:hover {
		@apply border-gray-200;
	}
}

.product-card-reviews {
	@apply mt-1 flex gap-x-1.5 items-center px-3 h-4;
	.search-results & {
		@apply justify-center;
	}
}

.description-wrapper {
	@apply max-w-[400px];

	// Workaround to fix line-clamp on Safari webkit
	& > p {
		display: inline;
	}
}
