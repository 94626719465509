@import 'swiper/scss';
@import 'swiper/scss/a11y';
@import 'swiper/scss/navigation';

.swiper {
	@apply relative max-w-full;
}

.swiper-slide {
	@apply h-auto;

	& > * {
		@apply h-full;
	}
}

.swiper-button {
	@apply button button-primary;
	@apply absolute w-8 h-8 p-0 rounded-full;
	min-width: auto; 

	&:after {
		content: '';
	}

	&.swiper-button-prev {
		@apply left-0 sm:-left-4;
	}

	&.swiper-button-next {
		@apply right-0 sm:-right-4;
	}

	&.swiper-button-disabled {
		@apply opacity-0;
	}
}
