.nav-item {
	@apply font-normal;
	transition: font-weight 0.3s ease;
}

.bottom-line-current {
	@apply relative;
	&:after {
		content: '';
		height: 2px;
		bottom: -1px;
		@apply absolute
			inset-x-0
			z-10
			w-full
			rounded-full
			scale-x-0
			opacity-0
            bg-gray-300;
		transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
	}
	&:hover,
	&:focus {
		&:after {
			@apply scale-x-50 opacity-100;
		}
	}
	&.current {
		&:after {
			@apply bg-bbx-spark scale-x-100 opacity-100;
		}
		&:hover,
		&:focus {
			&:after {
				@apply bg-bbx-spark;
				transform: scaleX(0.9);
			}
		}
	}
}

.desktop-nav-drawer {
	@apply absolute
        inset-x-0
        bg-white
        border-y
        border-gray-200
        py-12
        -z-10
        overflow-auto
        pointer-events-none;
	max-height: calc(100vh - 80px);
	transform: translateY(calc(-100% - 8px));
	transition: transform 0.6s var(--easing);
	&.is-open {
		@apply pointer-events-auto;
		transform: translateY(0);
	}
}

.mobile-nav {
	@apply fixed inset-0 z-20 bg-white flex flex-col border-l;
	transition: transform 0.6s var(--easing);
}
