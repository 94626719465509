@tailwind base;
@tailwind components;

:root {
	--easing: cubic-bezier(0.86, 0, 0.07, 1);
}

@layer base {
	html,
	body {
		@apply h-full;
		color: theme('colors.bbx.style');
	}
	body.no-scroll {
		overflow: hidden;
	}
	html {
		@apply antialiased scroll-smooth font-light;
		font-size: 15px;
	}

	body {
		@apply flex flex-col overflow-x-hidden;
	}

	main {
		@apply flex-1;
	}

	.section {
		@apply py-16 lg:py-24;
	}

	// Collapse whitespace
	.heading {
		+ .section {
			@apply pt-12;
		}
	}

	.container {
		@apply px-4 md:px-8;
	}

	h1 {
		&.product {
			@apply text-[32px] leading-tight;
		}
	}

	// Change default blue focus styles
	a,
	button {
		@apply focus:outline-none focus-visible:ring-2 ring-bbx-pastel-pink;
	}

	strong {
		@apply font-medium;
	}

	.scrollbar {
		// Scrollbars
		&::-webkit-scrollbar {
			width: 2px;
			height: 2px;
		}
		&::-webkit-scrollbar-track {
			@apply bg-gray-100;
			border-radius: 20px;
		}
		&::-webkit-scrollbar-thumb {
			@apply bg-gray-350;
			border-radius: 20px;
		}
	}

	::selection {
		@apply bg-bbx-spark/10 text-bbx-spark;
	}

	// @TJ custom double thumb slider
	// not working in firefox ATM
	// Double thumb range slider
	.refine-results-range-slider {
		@apply w-full appearance-none cursor-pointer absolute inset-0 h-6 py-0 px-1 border-0;

		// Chrome, Safari etc..
		&::-webkit-slider-runnable-track {
			// height: 26px;
			@apply rounded-full bg-transparent;
		}

		&::-webkit-slider-thumb {
			height: 18px;
			@apply appearance-none rounded-full bg-white z-50 aspect-square relative;
		}

		// Fireox
		&::-moz-range-track {
			// height: 26px;
			@apply rounded-full bg-transparent;
		}

		&::-moz-range-thumb {
			height: 18px;
			@apply appearance-none rounded-full bg-white z-50 aspect-square relative;
			// z-index: 2000;
		}
	}
}

// Backdrop color for dialog elements
dialog::backdrop {
	@apply bg-bbx-style/50;
}

// Altone
@import 'fonts';

// Themes
@import 'themes';

// Forms
@import 'forms';

a,
button {
	&.primary {
		@apply text-bbx-spark;
		transition: color 0.2s ease;
		&:hover {
			@apply text-bbx-spark;
		}
		&:active {
		}
	}
}

// Bullet list checkmark
.list-item-checkmark {
	@apply align-top pl-7 relative;

	&::before {
		@apply inline-block align-middle absolute left-0 mt-px;
		content: url(/images/checkmark-bullet.svg);
	}
}

.list-item-checkmark-white {
	@apply align-top pl-7 relative;

	&::before {
		@apply inline-block align-middle absolute left-0 mt-px;
		content: url(/images/checkmark-bullet-white.svg);
	}
}

@import 'button';

// Rich Text
.prose {
	@apply prose-strong:font-medium;
	h1,
	h2,
	h3 {
		@apply font-serif tracking-tight;
	}
	h4,
	h5,
	h6 {
		@apply font-medium;
	}
}

.video-embed {
	@apply my-8;
	iframe {
		width: 100%;
		height: auto;
		@apply aspect-video;
	}
}

.title {
	@apply font-serif;
	@apply tracking-tight;
	+ .subtitle {
		@apply mt-4;
		@apply tracking-tight;
	}
}

.small-underline {
	@apply relative;
	&:after {
		content: '';
		@apply absolute -bottom-4 left-1/2 -ml-4 w-8 h-1 bg-bbx-spark;
	}
}

.order-card {
	@apply flex
        flex-col
        items-stretch
        w-full
        h-full
        transition
        duration-200
        ease-in-out
        bg-white
        border
        rounded-sm
        shadow-bbx
        border-gray-200
        hover:border-bbx-spark
        hover:shadow-bbx-spark/20 overflow-x-hidden;
}

@import 'product-card';

.gallery-box {
	@apply p-6
        lg:p-20
        mx-auto
        relative
        justify-end
        aspect-[3/4]
        lg:aspect-[13/6]
        lg:min-h-[400px]
        max-h-[850px]
        flex
        flex-col;
	+ .section {
		@apply max-md:pt-36;
	}
}

.gallery-box-container {
	@apply relative z-10 flex justify-center max-md:-mb-16;
}

.product-card-image {
	@apply relative mb-6 h-36;
	transition: transform 0.2s ease;
	.product-card:hover & {
		transform: scale(1.07);
	}
}

.image-thumb-scroller {
	@apply flex items-center justify-start overflow-auto snap-x scroll-smooth;
	&::-webkit-scrollbar {
		display: none;
	}
}

@import 'spinner';

.faq-item {
	@apply bg-gray-50 rounded-lg;
	summary {
		@apply flex
            justify-between
            bg-gray-100
            px-4
            py-6
            rounded-lg
            items-center;
		cursor: pointer;
		list-style: none;
		&::-webkit-details-marker {
			display: none;
		}
		i {
			@apply text-2xl;
			transition: transform 0.3s ease;
			transform: rotate(0deg);
			transform-origin: center;
		}
	}
	.faq-item-body {
		@apply font-medium px-6 py-4;
	}
	&[open] {
		summary {
			i {
				transform: rotate(45deg);
			}
		}
	}
}

// This is some css that we needed to add to disable the my account content for the RC migration.  It is meant to be temporary and should be removed post migration.
.disable-content-area {
	input,
	select {
		color: #888888;
	}
	label[for='status'] {
		opacity: 0.4;
	}
	button {
		display: none;
	}
	position: relative;
	&:after {
		content: '';
		background-color: #fff;
		opacity: 0;
		position: absolute;
		inset: 0px 0px 0px 0px;
	}
	&:hover {
		&:after {
			opacity: 0.5;
		}
		&:before {
			content: 'Manage subscription settings are unavailable due to scheduled maintenance and will be back on May 9th. Please contact customer service at info@birchbox.com if you need further assistance.';
			position: absolute;
			z-index: 10;
			top: 50%;
			transform: translateY(-50%);
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			text-align: center;
			border-radius: 8px;
			padding: 40px;
			margin-top: -60px;
			@apply bg-bbx-pearl-light text-bbx-spark;
		}
	}
}

@import 'nav';
@import 'cart';
@import 'subscription-box';
@import 'swiper';
@import 'promo-bar';
@import 'current-member-sms.scss';
@import 'members-market';

#__next {
	@apply contents;
}

@tailwind utilities;
