.password-view-toggle-button {
	@apply uppercase text-xs font-medium absolute inset-y-2 right-2 w-12 primary;
}

// Forms
.select {
	select {
		// @apply w-full h-full;
		background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-size: 0.75rem;
		background-position: center right 16px;

		&.error {
			@apply border-bbx-red ring-bbx-red bg-bbx-red/10 placeholder:text-bbx-red text-bbx-red;
		}
	}
}

// Inputs & Selects
.input,
.select select {
	@apply bg-white
        appearance-none
        
        border
        p-4
        text-base
        border-gray-200
        placeholder:font-light
        font-medium
        outline-none;
	transition: border-color 0.15s ease;
	@screen max-md {
		font-size: 16px;
	}

	&:focus {
		@apply border-bbx-style;
	}
	&.error {
		@apply border-bbx-red ring-bbx-red bg-bbx-red/10 placeholder:text-bbx-red text-bbx-red;
	}
}

// Checkbox
.checkbox {
	@apply accent-bbx-spark w-6 h-6;
}

.error-message {
	@apply text-xs font-medium text-bbx-red md:text-sm;
}

// Placeholder animation input
.form-control {
	@apply relative flex flex-col justify-center;

	.label {
		@apply absolute
            left-4
            text-gray-500
            transition
            duration-200
            ease-out
            scale-x-100
            translate-y-0
            text-base
            origin-left;
	}

	.input,
	.select select {
		@apply w-full pt-7 pb-2;

		&:valid:not(.optional) + .label,
		&.is-filled + .label,
		&:focus + .label,
		&.error + .label {
			@apply -translate-y-2  scale-75;
		}

		&.error + .label {
			@apply text-bbx-red;
		}
	}

	.input + .label {
		@apply cursor-text;
	}

	.select {
		select {
			@apply pr-9;
		}
		&.cardSelect {
			select {
				@apply pt-2 border-0 bg-gray-100 mb-[16px] mb-0;
			}
		}
		+ .label {
			@apply -translate-y-2  scale-75;
		}
	}
	&.error {
		.select.cardSelect select {
			@apply bg-red-200;
		}
		.select select {
			@apply border-bbx-red ring-bbx-red bg-bbx-red/10 placeholder:text-bbx-red text-bbx-red;
		}
		.label {
			@apply text-bbx-red;
		}
	}
}

[type='email']:disabled {
	@apply text-gray-350;
}
