// Loading spinner
.spinner {
	@apply w-16 h-16 rounded-full border-8 border-white bg-white;
	background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.28.46a24 24 0 109.44 0l-.38 1.97a22 22 0 11-8.68 0L19.28.46z' fill='%23F98979'/%3E%3C/svg%3E");
	animation: spin 0.5s linear infinite;

	// Spinner for buttons
	.button & {
		@apply inline-block border-0 bg-transparent w-[15px] h-[15px];
		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.28.46a24 24 0 109.44 0l-.38 1.97a22 22 0 11-8.68 0L19.28.46z' stroke-width='4' stroke='white' fill='%23fff'/%3E%3C/svg%3E");
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
