// Foundational button styles
.button {
	@apply relative min-w-[8rem] max-w-full py-3 px-4 md:px-6 lg:px-8 appearance-none inline-flex items-center justify-center gap-2 border transform-gpu overflow-hidden border-transparent text-center whitespace-nowrap font-medium;
	transition: background-color 0.2s ease, border-color 0.2s ease, color 0.2s ease;

	// Overlay
	&:after {
		content: '';
		@apply absolute inset-0 bg-bbx-style opacity-0;
		transform: translateY(80%);
		transition: transform 0.2s ease, opacity 0.2s ease;
	}

	// Disabled states
	&:disabled,
	&:disabled:hover,
	.group:hover &:disabled,
	&:disabled:active,
	&.disabled {
		@apply bg-gray-200 text-gray-500 border-gray-200 pointer-events-none;
		svg {
			@apply fill-gray-500;
		}
	}
}

// Primary button with solid background
//I left -dark-blue because its used in cms, but would like to rely on secondary for future proof
.button-primary,
.button-dark,
.button-dark-blue {
	@apply bg-bbx-style hover:bg-bbx-spark text-white;

	&:hover,
	.group:hover & {
		@apply bg-bbx-spark;

		&:after {
			opacity: 0.04;
			transform: translateY(0);
		}
	}

	&:active {
		&:after {
			@apply opacity-10;
		}
	}
}

// Secondary button with border
.button-secondary {
	@apply border-bbx-style;

	&:after {
		content: '';
		@apply bg-bbx-style/50;
	}

	&:hover,
	.group:hover & {
		&:after {
			@apply opacity-20;
			transform: translateY(0);
		}
	}

	&:active {
		&:after {
			@apply opacity-30;
		}
	}
}

// call to action button with border
.button-action {
	@apply bg-bbx-spark hover:bg-bbx-style text-white;

	&:hover,
	.group:hover & {
		@apply bg-bbx-style;

		&:after {
			opacity: 0.04;
			transform: translateY(0);
		}
	}

	&:active {
		&:after {
			@apply opacity-10;
		}
	}
}

.button-action.light {
	&:hover,
	.group:hover & {
		@apply bg-white text-bbx-style;
		svg {
			@apply fill-bbx-style;
		}

		&:after {
			opacity: 0.04;
			transform: translateY(0);
		}
	}
}

// Link-style button
.button-link {
	@apply text-bbx-spark text-sm uppercase tracking-wide px-0 py-2;

	&:hover,
	.group:hover & {
		@apply text-bbx-spark;
	}

	&:after {
		@apply hidden;
	}
}

// Link-style button
.button-text {
	@apply px-0 py-2 underline;

	&:hover,
	.group:hover & {
		@apply text-bbx-spark;
		svg {
			@apply fill-bbx-spark;
		}
	}

	&:after {
		@apply hidden;
	}
}

.button-neon-green,
.button-pastel-pink,
.button-glow {

	&:after {
		content: '';
		@apply bg-bbx-style/50;
	}

	&:hover,
	.group:hover & {
		&:after {
			@apply opacity-20;
			transform: translateY(0);
		}
	}

	&:active {
		&:after {
			@apply opacity-30;
		}
	}
}

.button-neon-green {
	@apply bg-bbx-neon-green;
}

.button-pastel-pink {
	@apply bg-bbx-pastel-pink;
}

.button-glow {
	@apply bg-bbx-glow;
}