.promo-bar-wrapper {
	text-align: center;
	padding: 6px 20px;
	@apply bg-bbx-style;
	@apply text-white;
	padding: 8px 20px;
	font-size: 16px;

	&.hide {
		display: none;
	}

	&.visible {
		visibility: visible;
		height: initial;
		padding: 8px 20px;
		min-height: 37px;

		@media (max-width: 767px) {
			font-size: 14px;
		}
	}

	&.animate-appear {
		animation: appear 0.1s ease-out forwards;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes appear {
	0% {
		min-height: 0;
		padding: 0 20px;
	}

	100% {
		min-height: 37px;
		padding: 8px 20px;
	}
}