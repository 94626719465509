@keyframes tilt-shaking {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
}
#current_member_sms .mobile-phone-icon {
    animation: tilt-shaking 0.25s linear infinite;
    transform-origin: center center;
    margin: 20px auto;
}
#current_member_sms small a {
    text-decoration: underline;
}